globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"66d1b4e8bcd06113a331a8d0945c7b8577b81b1a"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { initSentry } from "./sentry.config"
import {
  browserTracingIntegration,
  browserProfilingIntegration,
} from "@sentry/nextjs"

initSentry({
  integrations: [browserTracingIntegration(), browserProfilingIntegration()],
  tracePropagationTargets: ["paragraph.xyz/api", "api.paragraph.xyz"],
})
